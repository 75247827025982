import React, { useCallback } from "react"
import PropTypes from "prop-types"
import TunnelCustomer from "../tunnel/customer/tunnelCustomer.component"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import { compose, required, postalCode, ensura } from "../../utils/validate"
import RadioGroup from "../../components/form/radioGroup.component"
import Dropdown from "../../components/form/dropdown.component"
import {
  specificityToString,
  getRedirectProductPerSpecificity,
  HOUSINGTYPE_APPARTEMENT,
  HOUSINGTYPE_MAISON,
  PRINCIPAL_HOUSING_SECOND,
  TUNNEL_SPECIFICITY_OCCUPANT,
  TUNNEL_SPECIFICITY_OWNER,
  TUNNEL_SPECIFICITY_RENTER,
  PRINCIPAL_HOUSING_FIRST,
  WATER_METER_POSITION_OTHER,
  HOUSINGTYPE_MIXTE,
  getWaterMeterPositions,
} from "../product/product.utils"
import Link from "../navigation/link.component"
import { usePaymentContext } from "./payment.provider"
import Address from "../../components/form/address.component"
import Flat from "../../components/pictos/flat.component"
import House from "../../components/pictos/house.component"
import { formTrackingErrors, usePaymentProductTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import HouseTypeRadioLabel from "./houseTypeRadioLabel.component"
import PaymentFormFooter from "./paymentFormFooter.component"
import SubmitButton from "../../components/form/submitButton.component"
import { Trans, useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import { useDraft } from "../../components/form/plugins/draft.component"
import { usePersist } from "../../components/form/plugins/persist.component"
import { useValueChanges } from "../../components/form/plugins/valueChanges.component"
import { omit } from "../../utils/collection"
import { useWebsiteConfiguration } from "../website/useWebsiteConfiguration.hook"

const formInitialState = {
  housingType: ``,
  postalCodeAndCity: ``,
  postalCode: ``,
  city: ``,
  address: ``,
  street: ``,
  streetNumber: ``,
  residence: ``,
  principalHousing: ``,
  propertyType: ``,
  propertyType2: ``,
  waterMeterPosition: ``,
}

function RedirectLink ({ product }) {
  return (
    <Link route="product" params={product} data-testid={arguments[0][`data-testid`] || undefined}>
      {product.name}
    </Link>
  )
}

RedirectLink.propTypes = {
  product: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

function useValidateWithProduct (maybeProduct, blacklistedPostalCodes) {
  return useCallback(
    (values, t) => {
      const product = maybeProduct ?? {}

      return {
        housingType: compose(
          required,
          value =>
            product.housingType !== value &&
            product.housingType !== HOUSINGTYPE_MIXTE && [
              `common:payment.page_step1_incompatible_housing_type`,
              {
                incompatibleHousingType:
                  product.housingType === HOUSINGTYPE_MAISON
                    ? t(`common:payment.page_step1_incompatible_flat`)
                    : t(`common:payment.page_step1_incompatible_house`),
              },
            ],
        )(values.housingType),
        postalCodeAndCity: compose(
          _values => required(_values.city),
          _values => required(_values.postalCode),
          _values => postalCode(product.blacklistedDepartments, blacklistedPostalCodes)(_values.postalCode),
        )(values),
        address: compose(() => {
          if (!values.city || !values.postalCode) {
            return `common:payment.page_step1_select_postal_code`
          }

          return null
        }, required)(values.address),
        residence: values.residence && ensura(values.residence),
        principalHousing: required(values.principalHousing),
        propertyType: values.principalHousing !== PRINCIPAL_HOUSING_SECOND && required(values.propertyType),
        propertyType2: values.principalHousing === PRINCIPAL_HOUSING_SECOND && required(values.propertyType2),
        waterMeterPosition:
          values.housingType === HOUSINGTYPE_MAISON &&
          product.sprf &&
          compose(required, value => {
            if (value !== WATER_METER_POSITION_OTHER) return

            if (product.otherRedirectProduct) {
              return (
                <Trans
                  t={t}
                  i18nKey="common:payment.page_step1_redirect_product"
                  components={{
                    linkToProduct: (
                      <RedirectLink
                        product={product.otherRedirectProduct}
                        data-testid="form_redirect_waterMeterPosition"
                      />
                    ),
                  }}
                />
              )
            }

            return `common:payment.page_step1_required_water_position`
          })(values.waterMeterPosition),
      }
    },
    [maybeProduct, blacklistedPostalCodes],
  )
}

function renderInfoWithProduct (t, product) {
  if (!product) {
    return () => ``
  }

  const { tunnelSpecificity } = product

  return (name, value) => {
    switch (true) {
      case name === `propertyType` &&
        tunnelSpecificity === TUNNEL_SPECIFICITY_OCCUPANT &&
        value === TUNNEL_SPECIFICITY_OWNER:
      case name === `propertyType` &&
        tunnelSpecificity === TUNNEL_SPECIFICITY_OWNER &&
        value === TUNNEL_SPECIFICITY_OCCUPANT:
      case name === `propertyType` && tunnelSpecificity === TUNNEL_SPECIFICITY_RENTER:
      case name === `propertyType2` &&
        tunnelSpecificity === TUNNEL_SPECIFICITY_RENTER &&
        value === TUNNEL_SPECIFICITY_OWNER:
      case name === `propertyType2` &&
        tunnelSpecificity === TUNNEL_SPECIFICITY_OWNER &&
        value === TUNNEL_SPECIFICITY_RENTER:
      case name === `propertyType2` && tunnelSpecificity === TUNNEL_SPECIFICITY_OCCUPANT: {
        const info = `${t(`common:payment.page_step1_specificity`, {
          specificity: specificityToString(t, tunnelSpecificity),
        })}`
        const redirectProduct = getRedirectProductPerSpecificity(product, value)

        if (redirectProduct) {
          return (
            <>
              {info}
              <br />
              <Trans
                t={t}
                i18nKey="common:payment.page_step1_redirect_specificity"
                values={{
                  specificity: specificityToString(t, value),
                }}
              />
              <RedirectLink product={redirectProduct} data-testid={`form_redirect_${name}`} />.
            </>
          )
        }

        return info
      }

      default:
        return ``
    }
  }
}

export default function Step1Page () {
  const { t } = useTranslation()
  const { product, step1, setStep1, isReady } = usePaymentContext()
  const { blacklistedPostalCodes } = useWebsiteConfiguration()
  const { navigate } = useNavigation()
  const waterMeterPosition = getWaterMeterPositions(t)

  useViewPageTracking(`TunnelCommande`, ``, `Tunnel - Logement à couvrir`)
  usePaymentProductTracking(product, 1, `CaractéristiquesLogement`)

  const validate = useValidateWithProduct(product, blacklistedPostalCodes)
  const renderInfo = renderInfoWithProduct(t, product)

  function onSubmit (values) {
    setStep1(omit(values, `postalCodeAndCity`))
    navigate(`paymentStep2`)
  }

  const draft = useDraft(`step1`)
  const persist = usePersist(
    isReady,
    () => step1,
    values => setStep1(omit(values, `postalCodeAndCity`)),
  )

  const valueChanges = useValueChanges(
    ({ field, value, prevValue, formik }) => {
      const { setFieldValue, setFieldTouched, errors } = formik

      switch (field) {
        case `principalHousing`:
          if (prevValue && prevValue !== value) {
            setFieldValue(`propertyType`, ``)
            setFieldValue(`propertyType2`, ``)
            setFieldTouched(`propertyType`, false)
            setFieldTouched(`propertyType2`, false)
          }
          break
        case `housingType`:
          if (value === HOUSINGTYPE_APPARTEMENT) {
            setFieldValue(`waterMeterPosition`, ``)
            setFieldTouched(`waterMeterPosition`, false)
          }
          break
      }

      formTrackingErrors(`Tunnel - Logement à couvrir`, field, value, renderInfo, errors[field])
    },
    [renderInfo],
  )

  return (
    <TunnelCustomer currentStep={0} title={t(`common:payment.page_step1_payment_title`)} product={product}>
      <Form
        name="tunnel_customer_step1"
        draft={draft}
        persist={persist}
        valueChanges={valueChanges}
        initialValues={formInitialState}
        onSubmit={onSubmit}
        validate={validate}
      >
        <Field
          component={RadioGroup}
          squareRadio
          name="housingType"
          label={t(`common:payment.page_step1_housing_label`) + ` * :`}
          items={[
            {
              label: <HouseTypeRadioLabel Picto={House}>{t(`common:payment.page_step1_house`)}</HouseTypeRadioLabel>,
              value: HOUSINGTYPE_MAISON,
            },
            {
              label: <HouseTypeRadioLabel Picto={Flat}>{t(`common:payment.page_step1_flat`)}</HouseTypeRadioLabel>,
              value: HOUSINGTYPE_APPARTEMENT,
            },
          ]}
        />

        <Address renderInfo={renderInfo} />

        <Field
          component={RadioGroup}
          squareRadio
          name="principalHousing"
          label={t(`common:payment.page_step1_radio_label_housing`) + ` * :`}
          help={t(`common:payment.page_step1_radio_help_housing`)}
          items={[
            { label: t(`common:generic.yes`).toUpperCase(), value: PRINCIPAL_HOUSING_FIRST },
            { label: t(`common:generic.no`).toUpperCase(), value: PRINCIPAL_HOUSING_SECOND },
          ]}
        />

        <Field
          component={RadioGroup}
          hide={values => values.principalHousing === PRINCIPAL_HOUSING_SECOND}
          squareRadio
          name="propertyType"
          label={t(`common:payment.page_step1_radio_label_property`) + ` * :`}
          help={t(`common:payment.page_step1_radio_help_property`)}
          renderInfo={renderInfo}
          items={[
            {
              label: t(`common:payment.page_step1_occupant`),
              value: TUNNEL_SPECIFICITY_OCCUPANT,
            },
            {
              label: t(`common:payment.page_step1_owner`),
              value: TUNNEL_SPECIFICITY_OWNER,
            },
          ]}
        />

        <Field
          component={RadioGroup}
          hide={values => values.principalHousing !== PRINCIPAL_HOUSING_SECOND}
          squareRadio
          name="propertyType2"
          label={t(`common:payment.page_step1_radio_label_property2`) + ` * :`}
          renderInfo={renderInfo}
          items={[
            {
              label: t(`common:payment.page_step1_property_item_label`),
              value: TUNNEL_SPECIFICITY_RENTER,
            },
            {
              label: t(`common:payment.page_step1_property_item_label2`),
              value: TUNNEL_SPECIFICITY_OWNER,
            },
          ]}
        />

        <Field
          hide={values => !product?.sprf || values.housingType !== HOUSINGTYPE_MAISON}
          component={Dropdown}
          name="waterMeterPosition"
          label={t(`common:payment.page_step1_watermeter_label`) + ` * :`}
          help={t(`common:payment.page_step1_watermeter_help`)}
          placeholder={t(`common:payment.page_step1_watermeter_placeholder`)}
          items={waterMeterPosition}
        />

        <PaymentFormFooter>
          <SubmitButton data-testid="tunnel_customer_step1_submit">
            {t(`common:generic.form_continue_button`)}
          </SubmitButton>
        </PaymentFormFooter>
      </Form>
    </TunnelCustomer>
  )
}
