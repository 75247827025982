import React from "react"
import PropTypes from "prop-types"
import InfoAndError from "./infoAndError.component"
import Label from "./label.component"
import { StyledField } from "./styledField.component"
import styled from "styled-components"
import { colors } from "../../modules/structure/theme"
import { useFormikContext } from "formik"

const Select = styled.select`
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  appearance: none;
  border-radius: 0;
  color: ${colors(`grey.g800`)};
  padding: 12px;
  line-height: 1.5;
  position: relative;
  border: none;
  background: ${colors(`grey.g300`)}
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 12px center;
  background-size: 8px 10px;
  border-bottom-style: solid;
  border-width: 0 0 3px;
  border-bottom-color: ${props => {
    if (props.isTouched) {
      if (props.hasError) {
        return colors(`error`)
      }

      return colors(`valid`)
    }

    return `transparent`
  }};

  &:focus {
    outline: none;
  }
`

export default function Dropdown ({ id, label, renderInfo, items, field, placeholder }) {
  const { name, value: selectedValue } = field
  const { handleChange, handleBlur, touched, errors } = useFormikContext()

  return (
    <StyledField>
      <Label>{label}</Label>
      <Select
        id={id}
        data-testid={`form_field_${name}`}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selectedValue}
        role="listbox"
        hasError={errors[name]}
        isTouched={touched[name]}
      >
        <option value="" key="empty" disabled>
          {placeholder}
        </option>
        {items.map(({ label: itemLabel, value }) => (
          <option value={value} key={value} role="option">
            {itemLabel}
          </option>
        ))}
      </Select>
      <InfoAndError field={field} renderInfo={renderInfo} />
    </StyledField>
  )
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
