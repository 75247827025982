import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors } from "../structure/theme"
import { flexCenter } from "../structure/theme/styles.component"

const Wrapper = styled.div`
  ${flexCenter};
`

const PictoContainer = styled.div`
  ${flexCenter};
  background-color: ${colors(`primary`)};
  border-radius: 50%;
  width: 40px;
  height: 40px;
`

const Text = styled.div`
  margin-left: 20px;
`

export default function HouseTypeRadioLabel ({ Picto, children }) {
  return (
    <Wrapper>
      <PictoContainer>
        <Picto size={30} color="white" />
      </PictoContainer>
      <Text ml={20}>{children}</Text>
    </Wrapper>
  )
}

HouseTypeRadioLabel.propTypes = {
  Picto: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
}
